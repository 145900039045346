<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
import { required, email, minLength } from "vuelidate/lib/validators";
import modal from "@/components/modal";

export default {
  page: {
    meta: [{ name: "Survey Details" }],
  },
  components: { Layout, PageHeader, modal },
  data() {
    return {
      xhrRequest: true,
      data: null,
      invitations: null,
      xhrInvitations: this.isEventPage ? true : false,
      investorModal: false,
      investors: [],
      subuser: {
        name: null,
        email: null,
        tel: null,
        password: null,
      },
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Link",
          field: "link",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      invitationsRows: [],
      inviteColumns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Fon",
          field: "fon",
        },
        {
          label: "Email",
          field: "email",
        },
      ],
    };
  },
  validations: {
    subuser: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(5) },
    },
    newUsageRight: {
      count: { required },
    },
  },
  created() {
    this.getData();

    if (this.isEventPage) {
      this.getInvitations();
    }
  },
  methods: {
    getInvestors() {
      let uri = "firms?isInvestor=true";
      HTTP.get(uri)
        .then((result) => {
          this.investors = [];

          if (result.data.rows) {
            result.data.rows.forEach((row) => {
              row.users.forEach((user) => {
                this.investors.push({
                  id: user.id,
                  name: user.name,
                  fon: row.name,
                  email: user.email,
                  phone: user.tel,
                });
              });
            });
          }
        })
        .then(() => {
          this.setExceptedInvestors();
        });
      // .catch(() => {
      //   Swal.fire({
      //     position: "top-end",
      //     icon: "error",
      //     title: "Error while getting investors data",
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // });
    },
    addNew() {
      let routeName = null;

      switch (this.$route.name) {
        case "investor-detail":
          routeName = "new-investors";
          break;
        case "company-detail":
          routeName = "new-company";
          break;
        case "event-detail":
          routeName = "new-event";
          break;

        default:
          break;
      }
      router.push({
        name: routeName,
      });
    },
    getAnswers() {
      HTTP.get("answers/" + this.$route.params.id)
        .then((result) => {
          this.invitations = result.data;

          this.invitations.forEach((invitation) => {
            this.invitationsRows.push({
              id: invitation.id,
              name: invitation.user.name,
              email: invitation.user.email,
              link: this.$appDomain + "survey/" + invitation.shortlink,
              shortlink: invitation.shortlink,
              status: invitation.answers ? "Filled" : "No Response",
            });
          });

          this.xhrInvitations = false;
        })
        .then(() => {
          this.getInvestors();
        });
    },
    getData: function () {
      this.xhrRequest = true;
      this.list = [];
      let uri = "surveys/" + this.$route.params.id;

      HTTP.get(uri)
        .then((result) => {
          this.data = result.data[0];
          this.xhrRequest = false;
        })
        .then(() => {
          if (this.isSurveyPage) {
            this.getAnswers();
            this.getInvestors();
          }
        })
        .catch((e) => {
          console.log("🚀 ~ file: detail.vue ~ line 171 ~ e", e);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
    setExceptedInvestors() {
      let sentIds = this.invitations.map((inv) => {
        return inv.userId;
      });

      this.investors = this.investors.filter((inv) => {
        return !sentIds.includes(inv.id);
      });
    },
    getInvitations: function () {
      let uri = "events/" + this.$route.params.id + "/invitations";
      this.invitations = [];
      HTTP.get(uri)
        .then((result) => {
          this.invitations = result.data.rows;

          this.invitations.forEach((invitation) => {
            this.invitationsRows.push({
              id: invitation.id,
              name: invitation.user.name,
              email: invitation.user.email,
              status: this.computeInvitationStatus(invitation.status),
            });
          });

          this.xhrInvitations = false;
        })
        .catch((e) => {
          console.log(e);
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrInvitations = false;
        });
    },
    computeInvitationStatus: function (status) {
      // 1=sent
      // 2=accepted
      // 3=maybe
      // 4=rejected
      let statusText = "";
      switch (status) {
        case 1:
          statusText = "Sent";
          break;
        case 2:
          statusText = "Accepted";
          break;
        case 3:
          statusText = "Maybe";
          break;
        case 4:
          statusText = "Rejected";
          break;
      }
      return statusText;
    },
    sendSurveyAgain(shortlink) {
      HTTP.get("surveys/" + shortlink + "/sendagain")
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Invitation sent again",
            showConfirmButton: false,
            timer: 500,
          });
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Getting error while trying to send invitation again.",
            showConfirmButton: false,
            timer: 500,
          });
        });
    },
    addParticipant: function () {
      let selectedInvestors = this.$refs["investors"].selectedRows
        ? JSON.stringify(this.$refs["investors"].selectedRows)
        : [];
      const surveyId = this.$route.params.id;

      HTTP.post("surveys/" + surveyId + "/add-participants", {
        selectedInvestors: selectedInvestors,
      }).then(() => {
        window.location.reload();
      });
    },
    seeResult(shortlink) {
      router.push({ name: "survey-answer", params: { shortlink } });
    },
  },
  watch: {
    "$route.name": function () {
      this.getData();
    },
  },
  computed: {
    pageTitle: function () {
      let pageTitle = null;
      switch (this.$route.name) {
        case "investor-detail":
          pageTitle = "Investor Detail";
          break;
        case "company-detail":
          pageTitle = "Company Detail";
          break;
        case "event-detail":
          pageTitle = "Event Detail";
          break;

        default:
          break;
      }
      return pageTitle;
    },
    isCompanyPage: function () {
      return this.$route.name == "company-detail";
    },
    isInvestorPage: function () {
      return this.$route.name == "investor-detail";
    },
    isEventPage: function () {
      return this.$route.name == "event-detail";
    },
    isSurveyPage: function () {
      return this.$route.name == "survey-detail";
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageTitle" />
    <modal
      v-if="investorModal"
      @close="investorModal = false"
      class="bigger-modal"
    >
      <div slot="title">Send Survey</div>
      <div slot="body">
        <div>
          <vue-good-table
            :columns="inviteColumns"
            :rows="investors"
            ref="investors"
             max-height="500px"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: false,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
          >
          </vue-good-table>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="investorModal = false"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="addParticipant"
        >
          Send Survey
        </button>
      </div>
    </modal>
    <!-- New User Modal -->
    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="d-flex w-100" v-if="!xhrRequest">
        <div class="col-lg-3">
          <div class="card">
            <!----><!---->
            <div class="card-body">
              <h4 class="card-title mt-3">
                <h4 class="card-title text-center">{{ data.name }}</h4>
              </h4>
              <div v-if="isEventPage">
                <div class="row mt-3">
                  <div class="col">
                    <i class="ri-calendar-2-line"></i>
                    {{ new Date(data.date).toLocaleDateString("en") }}
                  </div>
                  <div class="col">
                    <i class="ri-time-line"></i>
                    {{ new Date(data.date).toLocaleTimeString("en") }}
                  </div>
                </div>
                <div class="row text-center mt-3" v-if="!xhrInvitations">
                  <div class="col-6">
                    Total Invitations
                    <span class="hero-number">
                      {{ invitations ? invitations.length : 0 }}
                    </span>
                  </div>
                  <div class="col-6">
                    Accepted Invitations
                    <span class="hero-number">12</span>
                  </div>
                </div>
              </div>
            </div>
            <!----><!---->
          </div>
        </div>
        <div class="col-lg-9">
          <!-- Event Detail Invitations Page -->
          <div class="event-invitations card">
            <div class="card-body">
              <button
                type="button"
                class="btn btn-success floated-button"
                @click="investorModal = true"
              >
                + Add New
              </button>
              <h4 class="card-title">Survey Invitations</h4>
              <vue-good-table
                :columns="columns"
                :rows="invitationsRows"
                ref="invitations"
                :select-options="{
                  enabled: false,
                  selectOnCheckboxOnly: false,
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true,
                  selectAllByGroup: true,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'action'">
                    <button
                      class="btn btn-light"
                      v-if="props.row.status != 'Filled'"
                      @click="sendSurveyAgain(props.row.shortlink)"
                    >
                      <i class="ri-send-plane-fill"></i>
                      Send Again
                    </button>
                    <button
                      class="btn btn-light"
                      v-if="props.row.status == 'Filled'"
                      @click="seeResult(props.row.shortlink)"
                    >
                      <i class="ri-arrow-right-s-line"></i>
                      See Answers
                    </button>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
          <!-- Event Detail Invitations Page End -->
        </div>
      </div>
    </div>
  </Layout>
</template>